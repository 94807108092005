<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { FETCHSINGLEAPPRAISALACTION } from "@/store/modules/MA/appraisals/constants.js";
import accordion from "@/components/ui/baseComponents/accordion.vue";

const router = useRouter();
const store = useStore();
const route = useRoute();

const appraisalData = ref(null);
const groupedQuestions = ref({});
var headsArray = ref(["Appraisals (First-Half)", "Appraisals (Second-Half)"]);

onMounted(async () => {
  const response = await store.dispatch(
    `Appraisals/${FETCHSINGLEAPPRAISALACTION}`,
    {
      appraisal: route.params?.id || 0,
    }
  );
  const data = response?.data?.appraisal;
  console.log("data here :", data);
  console.log("data here :", data["appraisal_policies"].length);
  console.log("data here :", data["appraisal_policies"].length);
  let groupedQuestion = data["appraisal_policies"].length
    ? groupBy(data["appraisal_policies"][0]?.questions, "head")
    : {};
  //   groupedQuestion = [...groupedQuestion, ...data["candidate_questions"]];
  groupedQuestions.value = groupedQuestion;
  console.log("group questions: ", groupedQuestions.value);
  appraisalData.value = data;
});

const groupBy = (arr, key) => {
  const initialValue = {};
  return arr?.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};
const reRoute = () => {
  router.back();
};
</script>

<template>
  <div class="w-11/12 mx-auto my-5">
    <div
      class="flex items-center text-teal mb-5 cursor-pointer pt-2 pl-2 w-24"
      @click="reRoute"
    >
      <span class="material-icons-outlined"> arrow_back </span>
      go Back
    </div>
    <div v-if="appraisalData">
      <div class="flex justify-between">
        <div class="">
          <div class="flex items-center">
            <h1 class="mr-2">
              Name: {{ appraisalData?.user?.profile?.first_name }}&nbsp;{{
                appraisalData?.user?.profile?.last_name
              }}
            </h1>
          </div>
          <p><b>Role:</b> {{ appraisalData?.user?.profile?.primary_role }}</p>
          <p><b>Department:</b> {{ appraisalData?.user?.department?.name }}</p>
          <p>
            <b>Department Head:</b>
            {{
              appraisalData?.user?.department?.department_head?.profile
                ?.first_name
            }}
            {{
              appraisalData?.user?.department?.department_head?.profile
                ?.last_name
            }}
          </p>
        </div>
        <div class="">
          <p>
            <b>Date: </b>
            <span>{{ appraisalData?.date }}</span>
          </p>
          <p>
            <b>Appraisal Type: </b>
            <span>{{ appraisalData?.type }}</span>
          </p>
          <p>
            <b>Practice: </b>
            <span>{{ appraisalData?.practice?.practice_name }}</span>
          </p>
          <p>
            <b>Manager: </b>
            <span
              >{{
                appraisalData?.practice?.practice_manager?.profile?.first_name
              }}
              {{
                appraisalData?.practice?.practice_manager?.profile?.last_name
              }}</span
            >
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-y-2 my-5" v-if="groupedQuestions">
      <template v-for="item in Object.keys(groupedQuestions)" :key="item.index">
        <accordion
          :questionsData="groupedQuestions[item]"
          :title="headsArray[item - 1]"
        >
          <div class="flex flex-col gap-y-3">
            <accordion
              v-for="(question, index) in groupedQuestions[item]"
              :key="index"
              :isInner="true"
              :questionsData="question"
              :title="'Q: ' + question.question"
            >
              <div
                v-if="question.answer"
                class="bg-lightGrey px-5 py-2 rounded-md"
              >
                <p class="text-gray-500"><b>Ans:</b> {{ question?.answer }}</p>
              </div>
              <div
                v-if="question.appraisal_answers"
                class="bg-lightGrey px-5 py-2 rounded-md"
              >
                <p class="text-gray-500">
                  <b>Ans:</b> {{ question?.appraisal_answers[0]?.answer }}
                </p>
              </div>
              <div
                v-if="question.interview_answers"
                class="bg-lightGrey px-5 py-2 rounded-md"
              >
                <p class="text-gray-500">
                  <b>Ans:</b> {{ question?.interview_answers[0]?.answer }}
                </p>
              </div>
            </accordion>
          </div>
        </accordion>
      </template>
      <accordion
        :questionsData="appraisalData?.user?.objectives"
        :title="'Goals & Objectives'"
        :accordionText="'Objectives'"
      >
        <div class="flex flex-col gap-y-3">
          <div
            v-for="(objective, index) in appraisalData?.user?.objectives"
            :key="objective"
            class="bg-lightGrey px-5 py-2 rounded-md"
          >
            <p class="text-gray-500">
              {{ index + 1 }}. {{ objective?.objective }}
              <span v-if="objective?.status === 1"><b> (Completed)</b></span>
              <span v-if="objective?.status === 0"><b> (Incomplete)</b></span>
            </p>
          </div>
        </div>
      </accordion>
      <accordion :title="'Remarks'" :showQuestionsCount="false">
        <div class="flex flex-col gap-y-3">
          <div class="bg-lightGrey px-5 py-2 rounded-md">
            <p v-if="appraisalData.remarks != null" class="text-gray-500">
              {{ appraisalData?.remarks }}
            </p>
            <p v-else>Unavailable</p>
          </div>
        </div>
      </accordion>
    </div>
  </div>
</template>